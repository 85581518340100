import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer text-center">
        <div className="copyRight">
        <div>
              Copyright © 2020 www.youyan.cc. All Rights Reserved  北京趣听科技有限公司版权所有 <a href="http://beian.miit.gov.cn/" target="_blank" >京ICP备2020045549号-2</a> <a href="https://res.youyan.cc/images/1222323.jpg" target="_blank" >增值电信业务经营许可证</a> 
        </div>
      </div>
      </footer>
    );
  }
}
export default Footer;
