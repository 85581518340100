const defaultState = {
	server: {
		ucdata: {}
	}, 
	breadcrumb: { lists: [], buttons: [] },
	loading: false
};
export default (state = defaultState, action) => {
	// var newState = Object.create(state);
	var newState = {...state};
	// console.log("2 breadcrumb=>", action.type, newState, action);
	if (action.type === "LOADING") { 
		newState.loading = action.state; 
	}
	if (action.type === "SERVER") { 
		newState = { ...newState, ...action.data }; 
	}
	if (action.type === "BREADCRUMB") { 
		newState.breadcrumb = action.data; 
	}
	 
	return newState;
};
