import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import webapi from "@/utils/webapi";
import logo from "@/assets/images/logo.png";
// import style from "@/styles/style.less";
class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
	}
	componentWillReceiveProps(props) {
		this.props = props;
		this.setState({ ...props });
	}
	render() {
		const ucdata = this.state.server.ucdata;
		// console.log(this.state);
		return (
			<header>
				<div className="headCon d-flex justify-content-between">
					<div className="logo">
						<Link to="/">
							<img src={logo} alt="" />
						</Link>
					</div>
					{ucdata.user_id ? (
						<div className="rightBar d-flex ">
							<ul className="d-flex aligns-items-center">
								<li
									className="h_login"
									style={{ color: "#51595F" }}
								>
									{ucdata.nickname}
								</li>
								<li
									style={{ marginRight: 0 }}
									className="register"
								>
									{ucdata.user_id}
								</li>
							</ul>
						</div>
					) : (
						<div className="rightBar d-flex ">
							<ul className="d-flex aligns-items-center">
								<li
									className="h_login"
									style={{ color: "#51595F" }}
								>
									<a href="https://author.youyan.cc/" target="_blank">作家中心</a>
								</li>
								<li
									style={{ marginRight: 0 }}
									className="register"
								>
									<a href="https://author.youyan.cc/" target="_blank">注册作家</a>
								</li>
							</ul>
						</div>
					)}
				</div>
			</header>
		);
	}
}
export default connect((store) => ({ ...store }))(Header);
