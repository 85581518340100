import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import Header from "./components/header/header.js";
import Footer from "./components/footer/footer.js";
import Routes from "./routes/routing.js";
import webapi from "./utils/webapi.js";
import store from "./reducers/index.js";
// import "babel-polyfill";
import "./styles/style.css";
import "./styles/common.scss";
import Loadable from "react-loadable";
import Loadlab from "./loading";
import fakedelay from "./fakedelay";
// const Auth = Loadable({
//     loader: () => fakedelay(200).then(() => import("./page/authorize/auth")),
//     loading: Loadlab
// });
class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.server();
    }

    server() {
        const params = webapi.utils.query(); 
        params.test && webapi.cache.set("test", params.test); 
        webapi.server();
    }
    render() {
        // console.log('d=>',this.state,this.props);
        return (
            <BrowserRouter>
                <Provider store={store}>
                    <>
                        <Header {...this.state} />

                        <Switch>
                            {Routes.map((prop, key) => {
                                return (
                                    <Route
                                        exact
                                        path={prop.path}
                                        component={prop.component}
                                        key={key}
                                    />
                                );
                            })}
                        </Switch>

                        <Footer />
                    </>
                </Provider>
            </BrowserRouter>
        );
    }
}
export default App;
