import Loadable from "react-loadable";
import Loading from "../loading";
const Welcome = Loadable({
    loader: () => import("../page/welcome"),
    loading: Loading,
});
const Book = Loadable({
    loader: () => import("../page/book"),
    loading: Loading,
});
const Chapter = Loadable({
    loader: () => import("../page/chapter"),
    loading: Loading,
});
const Detail = Loadable({
    loader: () => import("../page/detail"),
    loading: Loading,
});
var Routes = [
    {
        id: "1",
        path: "/",
        name: "home",
        component: Welcome,
    },
    {
        id: "2",
        path: "/book/:method?/:id?",
        name: "book",
        component: Book,
    },
    {
        id: "3",
        path: "/chapter/:method?/:id?",
        name: "chapter",
        component: Chapter,
    },
    
    {
        id: "4",
        path: "/:book_id(\\d+)",
        name: "detail",
        component: Detail,
    },
    {
        id: "5",
        path: "/:book_id(\\d+)/:id(\\d+)",
        name: "book",
        component: Chapter,
    },
   
    
    // {
    //     id: "2",
    //     path: "/authorize/competence/:method?/:id?",
    //     name: "角色管理",
    //     component: Competence
    // }
];
export default Routes;
