import qs from "qs";
import { parse } from "querystring";
import axios from "axios";
import store from "../reducers/index.js";
import md5 from "md5";
import CryptoJS from "crypto-js"; 
const ToggleLoading = {
    type: "LOADING",
};
function ksort(param) {
    let dic = {};
    let sdic = Object.keys(param || {}).sort();
    sdic.map((item, index) => {
        dic[item] = param[sdic[index]];
        return index;
    });
    return dic;
}

//签名
function sign(param) {
    var d = {
        client_id: window.appid,
        timestamp: param.timestamp,
        url: encodeURIComponent(window.website_uri || ""),
        version: param.version,
    };
    return md5(
        qs.stringify(ksort(d)) + encodeURIComponent(window.secret || "")
    );
}
 
var cache = (function() {
    var get_key = function(key) {
        return "admin_" + key;
    };
    var clear_cookie = function() {
        var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
        if (keys) {
            for (var i = keys.length; i--; ) {
                document.cookie =
                    keys[i] + "=0;expires=" + new Date(0).toUTCString();
            }
        }
    };
    var clear_localStorage = function() {
        var storage = window.localStorage;
        for (var key in storage) {
            storage.removeItem(key);
        }
    };
    var get = function(key) {
        return window.localStorage
            ? window.localStorage.getItem(get_key(key))
            : utils.getcookie(get_key(key));
    };
    var set = function(key, val) {
        return window.localStorage
            ? window.localStorage.setItem(get_key(key), val)
            : utils.setcookie(get_key(key), val);
    };
    var clear = function() {
        return window.localStorage ? clear_localStorage() : clear_cookie();
    };
    return {
        set: set,
        get: get,
        clear: clear,
        clear_localStorage: clear_localStorage,
        clear_cookie: clear_cookie,
    };
})();
 

var ajax = (function() {
    var get = function(url, data, success, header, error) {
        return request("get", url, data, success, header, error);
    };
    var post = function(url, data, success, header, error, file = false) {
        return request("post", url, data, success, header, error, file);
    };
    var put = function(url, data, success, header, error, file = false) {
        return request("put", url, data, success, header, error);
    };
    var patch = function(url, data, success, header, error, file = false) {
        return request("patch", url, data, success, error, file);
    };
    var del = function(url, data, success, header, error) {
        return request("delete", url, data, success, error);
    };

    var headers = function(method) {
        var data = {
            "content-type": "application/x-www-form-urlencoded",
            "client-id": window.appid,
            Accept: "application/html",
        };
        data.sn = cache.get("field");
        data.token = utils.getcookie(cache.get("field"));
        data.timestamp = Date.parse(new Date()) / 1000;
        data.version = "1.0";
        data.sign = sign(data);
        data.format = "json";
        return data;
    };
    var request = async function(
        method,
        url,
        data,
        success,
        header,
        error,
        file = false
    ) {
        var options = {
            method,
            headers: headers(method),
            data: file ? data : qs.stringify(data),
            url:
                "/" + (method === "get" ? url + "?" + qs.stringify(data) : url),
            // url: "/" + (method === "get" ? url: url)
        };

        try {
            ToggleLoading.state = true;
            store.dispatch(ToggleLoading);
            let res = await axios(options);
            ToggleLoading.state = false;
            store.dispatch(ToggleLoading);
            var href = window.location.href;  
            //res.data.replace(/\n/g, "\\n").replace(/\r/g, "\\r")  
            var data =JSON.parse(utils.decrypt(res.data,window.cs,md5(res.headers.['content-encrypt']).substr(8,16))) || {};
            if (cache.get('test')=='development'||process.env.NODE_ENV==='development') {
                console.log(url+' encrypt=>',window.cs,md5(res.headers.['content-encrypt']).substr(8,16));
                console.log(url+' data=>',data);
                console.log(url+' request=>',res);
                console.log('env=>',process.env);
            }  
            if (success) {
                return success(data);
            }
            return data;
        } catch (err) {
            ToggleLoading.state = false;
            store.dispatch(ToggleLoading);
            console.error(err.message, options);
            var res = { message: err.message, status: "failure" };
            if (error) {
                return error(res);
            }
            return res;
        }
    };
    return {
        get: get,
        post: post,
        put: put,
        delete: del,
        header: headers,
    };
})();
var utils = (function() {
    var in_array = function(search, array) {
        for (var i in array) {
            if (array[i] === search) {
                return true;
            }
        }
        return false;
    };
    var setcookie = function(
        cookieName,
        cookieValue,
        expires,
        path,
        domain,
        secure
    ) {
        document.cookie =
            escape(cookieName) +
            "=" +
            escape(cookieValue) +
            (expires ? "; expires=" + expires.toGMTString() : "") +
            "; path=" +
            (path ? +path : "/") +
            (domain ? "; domain=" + domain : "") +
            (secure ? "; secure" : "");
    };
    var getcookie = function(name) {
        var arr = document.cookie.match(
            new RegExp("(^| )" + name + "=([^;]*)(;|$)")
        );
        if (arr != null) return unescape(arr[2]);
        return null;
    };
    var title = function(t) {
        document.title = t;
        var i = document.createElement("iframe");
        i.src = "/favicon.ico?v=" + Math.random();
        i.style.visibility = "hidden";
        i.style.width = "1px";
        i.style.height = "1px";
        i.onload = function() {
            setTimeout(function() {
                i.remove();
                document.title = t;
            }, 500);
        };
        return t;
    };
    var removeArrayByValue = function(attr, val) {
        for (var i = 0; i < attr.length; i++) {
            if (attr[i] == val) {
                attr.splice(i, 1);
                break;
            }
        }
        return attr;
    };
    const query = () => parse(window.location.search.split("?")[1]);
    const http_build_query = (data) => qs.stringify(data);
    const redirect = (data) => {
        var url = data.redirect || "";
        var reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
        var objExp = new RegExp(reg);
        if (objExp.test(url) == true) {
            window.location.replace(url);
        } else {
            window.location.replace("/" + (url || ""));
        }
    };
    /**
     * 加密
     * @param string word 待加密报文
     * @return string
     */
    const encrypt = (word, key, iv) => {
        let d = CryptoJS.AES.encrypt(word, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });

        return d.toString();
    };

    /**
     * 解密
     * @param string word 待解密报文
     * @return string
     */
    const decrypt = (word, key, iv) => {
        let d = CryptoJS.AES.decrypt(word, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });

        return d.toString(CryptoJS.enc.Utf8);
    };

    return {
        removeArrayByValue,
        in_array: in_array,
        setcookie: setcookie,
        getcookie: getcookie,
        title: title,
        query,
        http_build_query,
        redirect,
        encrypt,
        decrypt,
    };
})();
 
async function server() {
    var check = await ajax.get("server/check");
    if (check.code === 10000) {
        
        var server = {
            code: check.code,
            ucdata: check.ucdata,
            columns: check.columns,
            menus: check.menus,
            competence: check.competence,
        };
        const t = { type: "SERVER" };
        t.data = { server: server };
        store.dispatch(t);
        return server;
    }
    return { code: check.code };
}
 
export default {
    cache,
    ajax,
    utils, 
    store, 
    server
};
