import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import App from "./App"; 
const Root = () => {
	return (
		<BrowserRouter basename="/">
			<Route path="/" component={App}></Route>
		</BrowserRouter>
	);
};
ReactDOM.render(<Root />, document.getElementById("container"), function() {
	document.getElementById("spinner").style.display = "none";
	document.getElementById("container").style = "transition: all 1.5s; opacity: 1; ";
});