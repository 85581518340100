import React from "react";
export default function loading({ isLoading, pastDelay, error }) {
	if (isLoading && pastDelay) {
		return (
			<div
				className="loading"
				style={{
					display: "block"
				}}
			>
				<div className="arc"></div>
				<div className="txt"></div>
			</div>
		);
	} else if (error && !isLoading) {
		return <p>加载内容出现错误，请刷新再试!</p>;
	} else {
		return null;
	}
}